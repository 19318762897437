<template>
  <BaseModal
    classes="offer mm-offer-popup"
    click-to-close
    ssr
    content-class="offer__content"
    :z-index="10"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div
      class="mm-offer"
      :class="{ 'mm-offer__fit-content': !(isConfiguredTerms || offerDetails?.saleNotes) }"
    >
      <div class="mm-offer-inner">
        <OfferLabel
          v-if="declinedItemsCount"
          text="Ранее отклонено"
          label-class="offers-table__label mm-block-light-green"
        />
        <SvgIcon
          v-else-if="!!offerTypeIcon"
          class="mm-offer-svg"
          :src="offerTypeIcon"
        />
        <div class="mm-offer-item">
          <p class="mm-headline-3">
            {{ props.itemTitle }}
          </p>

          <button
            class="btn btn-secondary btn-text offer-btn"
            @click="emitClose()"
          >
            Закрыть
            <SvgIcon
              src="navigation/close-20px"
              :wrapper-classes="['mm-offer-close']"
            />
          </button>

          <BasketWarningRow
            v-if="declinedItemsCount"
            hide-select-button
            :warning-type="EWarningMessageType.Simple"
            :text="`Поставщик не подтвердил заказ данного товара в количестве <span>${declinedItemsCount} ${unitName}</span>`"
          />

          <div class="mm-offer-item-inner">
            <div class="mm-product-info">
              <div class="mm-product-info-items">
                <div class="mm-product-info-item">
                  <p class="mm-headline-5">
                    {{ props.offer?.available ? 'в наличии' : 'под заказ' }}
                  </p>

                  <p
                    v-if="props.offer?.available"
                    class="mm-caption-regular"
                  >
                    {{ props.offer?.stock }} {{ unitName }}
                  </p>
                </div>

                <div class="mm-product-info-item">
                  <p class="mm-headline-5">
                    {{
                      isUserLoggedIn ? OfferMapper.mapDeliveryDaysBeforeOnly(offer?.maxDeliveryDays) : '-'
                    }}
                  </p>
                  <p class="mm-caption-regular">
                    {{ isUserLoggedIn ? `${formatPrice(offer?.deliveryCost)}` : '-' }}
                  </p>
                </div>

                <div class="item-wrapper">
                  <div class="mm-product-info-item ">
                    <p class="mm-headline-5">
                      {{ formatPrice(offer?.unitPrice) }}
                    </p>
                    <p class="mm-caption-regular">
                      {{ formatQuantity(offer?.quantity ?? 1, productDetails?.unit?.precision, unitName) }}
                    </p>
                  </div>

                  <BaseTooltip
                    v-if="isConfiguredTerms"
                    arrow
                    hover
                    offset-distance="4"
                  >
                    <SvgIcon src="navigation/info-outline" />
                    <template #content>
                      <div class="mm-offer-terms">
                        <div class="mm-offer-term mm-offer-term--dark">
                          <p class="mm-headline-5">
                            Цена в зависимости от объема
                          </p>
                          <ProductCharacteristic
                            low-opacity
                            class="product-characteristic"
                            :characteristics="shippingItemCost"
                          />
                        </div>
                      </div>
                    </template>
                  </BaseTooltip>
                </div>
              </div>

              <ProductCharacteristic
                class="product-characteristic product-characteristic--left"
                :characteristics="shipping"
              />
            </div>

            <div class="mm-product-action">
              <p class="mm-headline-1">
                {{ formatPrice(currentOverallPrice.value?.price) }}
              </p>

              <div class="mm-product-action-breadcrumbs">
                <p class="mm-caption-regular">
                  {{ vatInfo.value?.label }} <span>{{ formatPrice(vatInfo.value?.value) }}</span>
                </p>

                <p class="mm-caption-regular">
                  {{ deliveryInfo.value?.label }} <span>{{ formatPrice(deliveryInfo.value?.value) }}</span>
                </p>
              </div>

              <div class="mm-product-action-buttons">
                <QuantityInput
                  v-model="itemsQuantity"
                  :min-value="1"
                  :max-value="DEFAULT_MAX_QUANTITY_VALUE"
                  :field-validation="numericValue"
                  :precision="UnitOkeiHelper.getModelUnitPrecision(productDetails?.unit)"
                  :modifier="props.offer?.stepQuantity"
                  @blur="onFocusChange(false)"
                  @focus="onFocusChange(true)"
                />

                <AddToCartButton
                  :quantity="preparedQuantity"
                  :offer-id="props.offer?.id"
                  btn-text="В корзину"
                  :loading="loading"
                  :disabled-add-button="disabledAddToCart?.value || isFocusQuantityInput"
                  :has-mapping="hasPimMapping"
                  :is-conflict-bases-category="isConflictBasesCategory"
                  :is-disabled-by-client-category-code="isDisabledByClientCategoryCode"
                  :disabled-by-offer-rules="OfferMapper.isOfferDisabledByRules(props.offer, preparedQuantity)"
                  :precision="productDetails.unit?.precision"
                  @on-add-to-cart="onAddToCart"
                  @on-auth-link-click="onAuthLinkClick"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="isConfiguredTerms || offerDetails.saleNotes"
          class="mm-offer__scroll-container-wrapper"
        >
          <div class="mm-offer__scroll-container">
            <div class="mm-offer-bottom-wrapper">
              <p class="mm-headline-4">
                Условия покупки
              </p>

              <div
                v-if="isConfiguredTerms"
                class="mm-offer-terms"
              >
                <div class="mm-offer-term value-semibold">
                  <p class="mm-headline-5">
                    Цена товара в зависимости от объема
                  </p>
                  <ClientOnly>
                    <ProductCharacteristic
                      class="product-characteristic"
                      :characteristics="shippingItemCost"
                    />
                  </ClientOnly>
                </div>
              </div>

              <template v-if="additionalParams">
                <p class="mm-headline-4">
                  Дополнительная информация
                </p>

                <div class="mm-offer-terms">
                  <div class="mm-offer-term">
                    <ClientOnly>
                      <ProductCharacteristic
                        class="product-characteristic"
                        :characteristics="additionalParams"
                      />
                    </ClientOnly>
                  </div>
                </div>
              </template>
            </div>

            <div
              v-if="offerDetails.saleNotes"
              class="mm-offer-description"
            >
              <p class="mm-headline-4">
                Описание от продавца
              </p>

              <p
                class="mm-body-regular-s"
                v-html="sanitize(offerDetails.saleNotes)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
import AddToCartButton from './AddToCartButton.vue';
import { DEFAULT_MAX_QUANTITY_VALUE } from 'constants/quantityValues.const';
import SvgIcon from 'shared/components/SvgIcon.vue';
import IProductCharacteristic from 'shared/models/productCharacteristic.model';
import {
  IProductResultedOffer,
  IProductOfferDetails,
  IProductCurrentOverallPrice,
  IProductDetails,
} from 'shared/models/product.model';
import QuantityInput from 'shared/components/QuantityInput.vue';
import BaseTooltip from 'shared/components/BaseTooltip.vue';
import { useField } from 'vee-validate';
import BaseModal from 'shared/components/modals/BaseModal.vue';
import { OfferMapper } from 'shared/utils/offerMapper.util';
import { useUserStore } from 'store/user.store';
import { storeToRefs } from 'pinia';
import { useAuthFnHelper } from 'composables/useAuthHelper';
import { formatPrice } from 'shared/utils/priceValueFormat.util';
import { EOfferCalcTypes } from 'shared/enums/offerCalcTypes.enum';
import { EWarningMessageType } from 'shared/enums/warningMessageType.enum';
import BasketWarningRow from 'components/basket/BasketWarningRow.vue';
import OfferLabel from 'components/OfferLabel.vue';
import { sanitize } from 'shared/directives/sanitize';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';
import { formatQuantity } from 'shared/utils/formatQuantity.util';
import { UnitOkeiHelper } from 'utils/unitOkeiHelper.util';
import { Ref, ComputedRef } from 'vue';

const userStore = useUserStore();
const { isUserLoggedIn } = storeToRefs(userStore);

const props = defineProps<{
  offer: IProductResultedOffer;
  offerDetails?: IProductOfferDetails;
  quantity: ComputedRef<number>;
  itemTitle: string;
  vatInfo: Ref<{ label: string, value: number }>;
  deliveryInfo: Ref<{ label: string, value: number }>;
  disabledAddToCart?: Ref<boolean>;
  loading?: boolean;
  isConfiguredTerms?: boolean;
  hasPimMapping?: boolean
  currentCalcType?: Array<EOfferCalcTypes>;
  currentOverallPrice?: Ref<IProductCurrentOverallPrice>;
  isMobile?: Ref<boolean>;
  productDetails: IProductDetails;
  isConflictBasesCategory?: boolean;
  isDisabledByClientCategoryCode?: boolean;
  unitName: string;
  declinedItemsCount?: number;
  ociMode?: boolean;
}>();

const emits = defineEmits<{
  (e: 'onAddCount', value: number): void;
  (e: 'onAddToCart'): void;
  (e: 'update:modelValue', value: boolean): void;
  (e: 'close'): void;
}>();

const isFocusQuantityInput = ref<boolean>(false);
const itemsQuantity = ref<number>(1);
const numericValue = useField('');
const shipping = computed<Array<IProductCharacteristic>>(
  () => OfferMapper.mapShipping(
    props.offer,
    props.quantity?.value,
    props.unitName, UnitOkeiHelper.getModelUnitPrecision(props.productDetails?.unit),
  ),
);
const additionalParams = computed<Array<IProductCharacteristic>>(() =>
  [
    {
      label: 'Страна производства',
      value: props.offerDetails?.countryOfOrigin?.name || props.productDetails?.countryOfOrigin || '-',
    },
    ...(props.offerDetails?.parameters?.map((param) => ({ label: param.name || '', value: `${param.value || ''} ${param.unit || ''}` })) || []),
  ],
);
const watchSubscription = new WatchSubscription();
const shippingItemCost = computed<Array<IProductCharacteristic>>(() => OfferMapper.mapTermsPriceToChars(props.offer.termsPrice, props.unitName));
const offerTypeIcon = computed(() => {
  if (!props.offer?.bestPrice && !props.offer?.bestDelivery) {
    return '';
  }

  if (!props.ociMode) {
    return props.offer?.bestPrice ? 'indicators/best-price' : 'indicators/fast-shipping';
  }

  return props.offer.bestDelivery ? 'indicators/fast-shipping' : '';
});

const preparedQuantity = computed(
  () => OfferMapper.mapOfferQuantity(
    props.offer.stepQuantity,
    props.offer.minQuantity,
    +props.quantity?.value || 1,
    UnitOkeiHelper.getModelUnitPrecision(props.productDetails?.unit),
  ),
);

const { showLoginModal } = useAuthFnHelper();

function onFocusChange(isFocused: boolean): void {
  isFocusQuantityInput.value = isFocused;
}

function onAuthLinkClick() {
  emitClose();
  showLoginModal();
}

function onAddToCart() {
  emits('onAddToCart');
  emitClose();
}

function emitClose(): void {
  emits('update:modelValue', false);
}

watchSubscription.add(
  watch(
    () => props.quantity.value,
    (newQuantity) => itemsQuantity.value = newQuantity || 1,
    { immediate: true, deep: true },
  ),

  watch(
    itemsQuantity,
    () => emits('onAddCount', itemsQuantity.value),
  ),

  watch(
    () => props.isMobile.value,
    (newValue) => {
      if (newValue) {
        emits('close');
      }
    },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

:deep(.mm-tooltip.mm-tooltip--base) {
  width: unset !important;
}

.mm-offer {
  padding: 40px;
  max-height: 856px;
  height: 100%;
  width: 1090px;

  .mm-offer-item {
    .mm-basket-warning-row.mm-basket-warning-row--simple {
      margin-bottom: 32px;

      :deep(.mm-basket-warning-row__text) {
        > span > span {
          font-weight: 500;
        }
      }
    }
  }

  &__fit-content {
    height: fit-content;

    .mm-offer-item {
      border: 0;
      padding-bottom: 0;
    }
  }

  &-inner {
    padding: 0 !important;

    .offers-table__label {
      position: absolute;
      top: 4px;
      left: 4px;
      font-size: 12px;
    }
  }

  &__scroll-container-wrapper {
    margin-top: 40px;

    .mm-offer-bottom-wrapper {
      .mm-offer-terms {
        gap: 135px;

        .mm-offer-term {
          flex: 0;
        }
      }
    }
  }
}

.value-semibold {
  :deep(.mm-product-characteristics) {
    margin-right: 0 !important;

    .mm-product-characteristic-value {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

:deep(.mm-add-cart__btn) {
  .btn-primary {
    user-select: none;
    height: 40px;
    display: flex;
    justify-content: center;
  }

  .mm-offers-expanded__added-cart {
    display: flex;
    align-items: center;
    margin-left: 8px;

    &-text {
      font-size: 14px;
      line-height: 20px;
      margin-left: 12px;
    }
  }
}

:deep(.mm-tooltip--base) {
  margin-top: -3px;
}

.mm-offer-term--dark {
  :deep(.mm-product-characteristic-wrapper) {
    &::before {
      opacity: .2;
    }

    .mm-product-characteristic-label {
      background-color: $text-black;
    }

    .mm-product-characteristic-value {
      background-color: $text-black;
      color: $text-white;
    }
  }
}

@media screen and (max-width: 1279px) and (min-width: 768px) {
  .mm-offer {
    padding-right: 35px;

    &-inner {
      padding-right: 8px !important;
    }
  }
}
</style>
